/** AMPLIFY STYLE START */
// [data-amplify-authenticator][data-variation=modal] {
//   overflow-y: auto;
//   width: var(--amplify-components-authenticator-modal-width);
//   height: var(--amplify-components-authenticator-modal-height);
//   background-color: var(--amplify-components-authenticator-modal-background-color);
//   position: fixed;
//   top: var(--amplify-components-authenticator-modal-top);
//   left: var(--amplify-components-authenticator-modal-left);
//   /* Having a z-index at least "wins" by default */

// }
[data-amplify-authenticator][data-variation="modal"] {
  background-color: #e5e5e5 !important;
  z-index: 100000000 !important;
}
[data-amplify-authenticator] [data-amplify-router] {
  border: 0 !important;
  box-shadow: none !important;
}
.amplify-input {
  border: 0.5px solid #afafaf !important;
  background-color: #f1f1f1 !important;
  font-style: normal;
  font-weight: 400;
  font-size: (20px);
  line-height: em(24px);
  color: #6c6c6c;
}
.amplify-tabs {
  display: none !important;
}
[data-amplify-authenticator] [data-amplify-form] {
  padding: 1rem 2rem !important;
}
.amplify-button[data-variation="primary"] {
  background: linear-gradient(180deg, #1a71b0 0%, #354d97 100%);
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
  border: 0.5px solid #afafaf !important;
  background: #f1f1f1 !important;
}

/** AMPLIFY STYLE END */
