@import "~style/variables";
@import "~style/mixins";

/**
* This style depends on `_amplify-custom.scss`
*/

.container__logo {
  padding: em(15px) 0 em(5px) 0;
  background-color: white;
  display: flex;
  justify-content: center;
}

.container_login__forgot_pass {
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  font-size: em(16px);
  line-height: em(19px);
  text-decoration-line: underline;
  color: #000000;
  padding: em(12px) em(0);
}

.container_login__sign_up {
  padding: em(10px) 0 em(10px) 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  font-weight: 400;
  font-size: em(16px);
  line-height: em(19px);
  text-align: center;
  color: #000000;

  .sign_up_text {
    font-weight: 500;
    color: #1a71b0;
  }
}
