@import "@aws-amplify/ui-react/styles.css";

@import "~style/variables";
@import "~style/normanize";
// @import "~style/reset";
@import "~style/global";
@import "~style/mixins";
@import "~style/amplify-custom";

/** Formulare **/
input [type="search"] {
  background: black;
}

.h-min100 {
  min-height: 100vh;
}
.p-relative {
  position: relative;
}
printElement {
  display: none;
  @media print {
    display: block;
  }
}
@media print {
  @page {
    size: A4;
    margin: 10mm 10mm 25mm 10mm;
  }
  * {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  html,
  body {
    border: 1px solid white;
    height: 99%;
    page-break-after: avoid !important;
    page-break-before: avoid !important;
    margin: 20mm 0 0 0;
  }
  body * {
    visibility: hidden;
  }
  printElement,
  printElement * {
    visibility: visible;
  }
  printElement {
    position: absolute;
    table-layout: fixed;
    left: 0;
    top: 0;
  }

  // #section-to-print,
  // #section-to-print * {
  //   visibility: visible;
  // }
  // #section-to-print {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
  #not-print {
    display: none;
  }
}

.cart-add,
.cart-remove {
  align-items: center;
  width: max-content;
  background-color: white;
  border: 2px solid $color-primary;
  color: $color-primary;
  border-radius: 5px;
  padding: 5px 10px;

  &:hover {
    color: white;
    background-color: $color-primary;
  }
}

.cart-count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  position: absolute;
  top: 10px;
  left: 13px;
  font-weight: 900;
}

.cart-dropdown {
  height: 320px;
  width: 600px;

  position: absolute;
  right: 0px;
  top: 55px;
  border: 1px solid $color-primary;
  background-color: white;

  button {
    width: 90%;
    background-color: black;
    color: white;
    padding: 18px 0;
    margin-left: 5%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.808);
    }
  }
}

.cart-items {
  width: 90%;
  height: 220px;
  overflow-y: auto;
  margin: auto;
  background-color: white;
  color: black;
  padding-top: 30px;
}

.cart-item {
  display: grid;
  grid-template-columns: 20% 60% 20%;
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}

.item-detail-1,
.item-detail-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 300;
  }
}

.item-detail-1 {
  color: grey;
}

.cart-dropdown-total {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.cart-icon {
  font-size: 30px;
  position: relative;
  cursor: pointer;
}

.color-primary {
  color: $color-primary;
}

.color-white {
  color: $color-white;
}

.header-container {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5%;
  position: sticky;
  top: 0;
  background-color: white;
}

.icon-taka {
  font-size: 12px;
}

.logo-container {
  flex: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-left: 20%;
    height: 40px;
    object-fit: cover;
  }
}

.nav-items {
  flex: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-item {
  margin-left: 50px;
}

.search-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 0;
  width: 60%;
  margin-left: 35%;
  margin-top: 100px;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.product-card {
  display: flex;
  flex-direction: column;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.product-details {
  display: flex;
  flex-direction: column;
  background-color: rgba(212, 206, 206, 0.192);
  padding-top: 20px;
  padding-left: 5%;
  padding-bottom: 20px;

  h2 {
    color: $color-primary;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 10px;
  }
}

.product-card-button-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.product-card-quantity {
  background-color: white;
  width: 100px;
  text-align: center;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  border: 1px solid $color-primary;
}

.vehicle-header-container {
  height: 70px;
  width: 100%;
}

.close-quotation {
  font-size: 30px;
  position: absolute;
  right: 5%;
  top: 30px;
  color: white;
}

.quotation-logo {
  width: 100px;
  position: absolute;
  left: 5%;
  top: 15px;
}
.overview-table-row-header-2 {
  display: grid;
  grid-template-columns: 3% 20% 7% 14% 14% 14% 14% 14%;
  width: 90%;

  h4 {
    border: 1px solid rgba(128, 128, 128, 0.534);
    background-color: rgba(179, 175, 175, 0.082);
    padding: 10px 2px;
    font-size: 14px;
  }
}

.overview-table-row-elements-2 {
  display: grid;
  grid-template-columns: 3% 20% 7% 14% 14% 14% 14% 14%;
  width: 90%;

  p {
    border: 1px solid rgba(128, 128, 128, 0.534);
    padding: 10px 2px;
    font-size: 14px;
  }
}

// .quotation-cart-quantity {
//   text-align: center;
// }

.overview-table-last-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  div {
    height: 50px;
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.534);

    p {
      padding: 10px 2px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.overview-table-last-row-2 {
  display: grid;
  grid-template-columns: 3% 20% 7% 14% 14% 14% 14% 14%;
  width: 90%;
  div {
    height: 50px;
    width: 100%;
    border: 1px solid rgba(128, 128, 128, 0.534);

    p {
      padding: 10px 2px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.vehicle-select-container {
  display: flex;
  border: 2px solid $color-primary;
  cursor: pointer;
  width: 500px;
  padding: 20px;
  margin-bottom: 50px;

  &:hover {
    background-color: $color-primary;
    color: white;
  }
}

.selected-vehicle-container {
  width: 100%;
  height: 80px;
  background-color: $color-primary;
  margin-bottom: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overview-span {
  font-weight: 300;
}

.vehicle-item-container {
  margin-top: 100px;
}

.without-vehicle-container {
  margin-top: 100px;
}
