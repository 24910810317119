//320px, 640px, 1024px, 1400px
$breakpoints-up: (
  "small": "20em",
  "medium": "40em",
  "large": "64em",
  "xlarge": "87.5em",
);

//319px, 639px, 1023px, 1399px
$breakpoints-down: (
  "xsmall": "19.9375em",
  "small": "39.9375em",
  "medium": "63.9375em",
  "large": "87.4375em",
);

// @mixin mobile-only() {
//   @include media-query($mobile-max-width) {
//     @content;
//   }
// }

// @mixin desktop-only() {
//   @include media-query($bp-min: 992px) {
//     @content;
//   }
// }

@mixin breakpoint-min-w($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}

@mixin breakpoint-max-w($size) {
  @media (max-width: map-get($breakpoints-down, $size)) {
    @content;
  }
}

$browser-context: 16;

@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

@function rem($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke * -1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i * 1px $j * 1px 0 $color, comma);
    }
  }
  @return $shadow;
}

// @mixin stroke($stroke, $color) {
//   text-shadow: stroke($stroke, $color);
// }
