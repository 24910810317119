@tailwind base;
@tailwind components;
@tailwind utilities;
@import url($main-font-url);

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .default-scrollbar::-webkit-scrollbar {
      display: block;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  // font-family: "Poppins", sans-serif;
  font-family: $main-font-family;
  -webkit-tap-highlight-color: transparent;
  letter-spacing: em(-0.5px);
}

input[type="number"],
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

// html {
//   font-size: 100%;
//   box-sizing: border-box;
//   scroll-behavior: smooth;
// }

// body {
//   margin: 0;
//   padding: 0;

//   &.noscroll {
//     overflow: hidden;
//   }
// }

// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
//   font-family: "Poppins", sans-serif;
// }

// body {
//   overflow-x: hidden;
// }

// a {
//   text-decoration: none;
//   color: inherit;
// }

// p {
//   font-size: 16px;
// }

// button {
//   background: transparent;
//   border: none;
//   cursor: pointer;

//   -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
//   :focus {
//     outline: none;
//     box-shadow: none;
//   }

//   :active {
//     outline: none;
//     box-shadow: none;
//     border-style: outset;
//   }
// }
