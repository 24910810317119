@import "~style/variables";
@import "~style/mixins";

.loading_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: loading-container 0.5s linear infinite alternate;

  img {
    width: em(100px);
    position: absolute;
    z-index: -1;
  }
}

.loading_content {
  height: em(175px);
  width: em(175px);
  border: em(10px) solid $color-primary;
  border-top-color: rgb(140, 160, 165);
  border-radius: 50%;
  animation: loading 1s linear infinite;
  background-color: white;
  z-index: -2;
}

@keyframes loading {
  to {
    transform: rotate(1turn);
  }
}

@keyframes loading-container {
  to {
    transform: scale(1.1);
  }
}

.text_one {
  font-size: em(20px);
  font-weight: 700;
  color: $color-primary;
  letter-spacing: em(-1px);
}
